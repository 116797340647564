import React, {useState, memo, useCallback}  from 'react'
import PropTypes from 'prop-types'
import config from '../config.json'
import { Nav, Button, Container, Row, Col} from 'react-bootstrap'
import Register from '../page/Register'
import { Person } from 'react-bootstrap-icons'

let timeout = null
const Header = memo((props) => {
  const { changeLanguage, languageNext, user, i18nLabels, loaduser} = props
  const [show, setShow] = useState(false)

  const handleShow = useCallback((e) => {
    setShow(!show)
    e.preventDefault()
  }, [show, setShow])

  return (
    <>
      <header key={0} className="app-header noprint">
        <Container fluid="md" style={{alignItems: 'center'}}>
          <Row>
            <Col className="col-4"><h4>{config.name}</h4></Col>
            {(user) && 
            <Col className="col-4" style={{color: '#fff', margin: 'auto 0'}}><h6><a>{user.name}</a></h6></Col>
            }
            <Col className="col-4 printButton" >
              <Nav.Item className="languageButton" >
                <Button variant="secondary" type="submit" name="submit" onClick={handleShow} size="lg"><Person /></Button>
              </Nav.Item>
              {languageNext ?
                <Nav.Item className="languageButton" >
                  <Button variant="dark" type="submit" name="submit" onClick={() => changeLanguage() } size="lg">{languageNext}</Button>
                </Nav.Item>
                : ''
              }
              <Nav.Item >
                <Button variant="secondary" type="submit" name="submit" onClick={() => {
                  window.dispatchEvent(new CustomEvent('beforeprint', {detail: 'beforeprint'}))
                  if(timeout){ clearTimeout(timeout) }
                  // Chrome BUG!
                  timeout = setTimeout(async() => {
                    window.print()
                  }, 500)
                } } size="lg">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16">
                    <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                    <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"/>
                  </svg>
                </Button>
              </Nav.Item>
            </Col>
          </Row>
        </Container>
      </header>
      {show &&
        <Container key={1} fluid="md">
          <Register i18nLabels={i18nLabels.register} user={user} loaduser={loaduser} />
        </Container>
      }
    </>
  )
})
Header.displayName='Header'
Header.propTypes = {
  i18nLabels: PropTypes.object,
  changeLanguage: PropTypes.func.isRequired,
  languageNext : PropTypes.string,
  user : PropTypes.object,
  loaduser: PropTypes.func,
}
export { Header as default }

/*

        <Nav className="justify-content-center">
          <Nav.Item>
            <h4>{config.name}</h4>
          </Nav.Item>
        </Nav>
*/

