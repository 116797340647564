import { parse } from './data'
import config from '../config.json'

export const get = async({i18nLabels, identifier, position, type, country, language}) => {
  let result = {}
  if(identifier && identifier.length > 0 && type && type.length > 0){  
    try{
      const query = type ? config.rest.search : config.rest.searchVehicle
      let response = null
      let data = null
      let positionExtra = ''

      // if(identifier === 'TESTTESTTEST'){
      //  data = require('../../support/test.new.json') 
      //  data = data.publication ? data.publication : data
      //  response = {ok : true}
      //  // console.log('get:../../support/test.new.json:', data)
      // } else {
      if(type === 'TRAIN'){
        positionExtra = `&position=${position}`
      }
      response = type 
        ? await fetch(`https://${config.domain}${query}?nationality=${country}&identifier=${identifier}&type=${type}${positionExtra}`)
        : await fetch(`https://${config.domain}${query}?nationality=${country}&licenseplate=${identifier}`)
      data = await response.json()
      // }
      // console.log(data)

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        result.error = (data && data.message) || response.statusText
      } else {
        result = parse(data, language)
        result.search = { 
          identifier,
          country,
          type
        }
      }
    } catch(error) {
      console.error('service:get:ERROR', error)
      result.error = error.toString()
    }
  } else {
    result.error = i18nLabels.error.input.wrong
  }
  return result
} // &identifier=TE-ST123&reason=EMERGENCY_SERVICES&type=ROAD

export const register = async(put) => {
  let result = {
    status: null,
    error: null,
    data: null,
  }
  let response = null
  try{
    const query = config.rest.register
    response = put ?
      await fetch(`https://${config.domain}${query}?`,{
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json, text/plain, */*',
          // 'Accept': 'application/json',
          // 'Origin': 'https://devapp.tp1.de',
          // 'Access-Control-Allow-Origin': 'https://devapp.tp1.de, https://dev.tp1.de',
          // 'Access-Control-Allow-Methods': 'GET, POST, PUT',
          // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        body: JSON.stringify(put)
      })
      : 
      await fetch(`https://${config.domain}${query}?`)

    let data = {} 
    try{
      data = await response.clone().json()
    } catch (error){
      console.error('service:register:DATA', error)
      data = await response.text()
    }

    console.log('service:register:', response, 'data:', data)
    // check for error response
    result.status = response.status
    result.data = data
    if (!response.ok) {
      // get error message from body or default to response statusText
      result.error = (data && data.message) || (response.statusText && response.statusText.length > 0)
    }
  } catch(error) {
    console.error('service:register:ERROR', error, response)
    result.error = error.toString()
    result.status = 404
  }
  return result
}

export const getURLQueries = (query_array, equal = '=') => {
  let found = false
  let results = {}
  let elements = location.search.split('&')
  if(elements && elements.length > 0){
    // console.log('getURLQueries0', elements)
    let first = elements[0].split('?')
    elements[0] = first[1]
    if(elements[0] && elements[0].length > 0){
      try{
        let value = null
        let index = null
        let result = null
        for(let key in query_array){
          value = query_array[key]
          index = elements.findIndex(a =>a.includes(value))
          if(index >= 0){
            // console.log('decodeURI', result, decodeURI(result))
            // console.log('getURLQueries', index, elements[index])
            result = elements[index].replace(value+equal, '')
            // console.log('getURLQueries', index, elements[index], value, result)
            // TODO ARRAY!
            found = true
            // console.log('decodeURIComponent', result, decodeURIComponent(result))
            results[value] = decodeURIComponent(result)
          }
        }
      } catch(error) {
        console.error('getURLQueries', error)
      }
    }
  }
  // console.log('getURLQueries', results)
  return found ? results : null
}

export const setURLParams = (keys, values) => {
  if ('URLSearchParams' in window && window.history && keys && values && keys.length === values.length) {
    let params = ''
    // let searchParams = new URLSearchParams(window.location.search)
    // console.log('searchParams', searchParams.toString())
    for(let i = 0; i < keys.length; i++) {
      // console.log('encodeURI', values[i], encodeURI(values[i]))
      // console.log('encodeURIComponent', values[i], encodeURIComponent(values[i]))
      // searchParams.set(keys[i], encodeURIComponent(values[i]))
      params += keys[i] + '=' + encodeURIComponent(values[i]) + '&'
    }
    // let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
    let newRelativePathQuery = window.location.pathname + '?' + params
    // console.log('newRelativePathQuery', newRelativePathQuery)
    history.pushState(null, '', newRelativePathQuery)
  }
}