import React, {memo} from 'react'
import logo1 from '../images/star.trac-Logo.png'
import logo2 from '../images/rJVcd.png'
import PropTypes from 'prop-types'
import {Container, Col, Row } from 'react-bootstrap'

const Footer = memo((props) => {
  const { i18nLabels } = props
  return (
    <footer className="noprint" id="myFooter">
      <Container fluid>
        <Row>
          <Col xs={6}><img alt="" className="logo-one" height={65} src={logo1}/></Col>
          <Col xs={6}><img alt="" className="logo-two" src={logo2}/></Col>
        </Row>
      </Container>
      <div className="footer-copyright">
        <p>{i18nLabels.copyright}</p>
        <div style={{fontSize: '12px', color: '#bfbfbf'}}>
          <a style={{fontSize: '12px', color: '#bfbfbf'}} href="https://gbk-trustedpartner.de/impressum">Impressum</a> | <a style={{fontSize: '12px', color: '#bfbfbf'}} href="https://gbk-trustedpartner.de/datenschutz">Datenschutz</a> | <a style={{fontSize: '12px', color: '#bfbfbf'}} href="https://gbk-trustedpartner.de/en/imprint">Imprint</a> | <a style={{fontSize: '12px', color: '#bfbfbf'}} href="https://gbk-trustedpartner.de/en/privacy">Data Policy</a>
        </div>
      </div>
    </footer>
  )
})
Footer.displayName='Footer'
Footer.propTypes = {
  i18nLabels: PropTypes.object.isRequired,
}
export default Footer

