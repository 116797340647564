import React, {StrictMode} from 'react'
import { createRoot } from 'react-dom/client'
import './css/index.css'
import App from './App.js'

const container = document.getElementById('root'),
      root = createRoot(container)

root.render(
  <StrictMode>
    <App />
  </StrictMode>
)

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
if(typeof process !== 'undefined' && process.env.NODE_ENV !== 'production') {
  const reportWebVitals = require('./reportWebVitals').default
  reportWebVitals()
}
