/* eslint-disable no-unused-vars */
/*
 * Copyright (C) 2020 by Titus Rosu - StarData GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 * See the LICENSE file at the top-level directory of this distribution
 * for licensing information.
 *
 * Created by Titus Rosu <titus.rosu@gmail.com>, 30.07.20 11:21
 * Last modified 30.07.20 10:39
 */
class SessionClass {

  constructor(){
    this.element = typeof window !== 'undefined' ? localStorage : {
      getItem : (key) => { return null},
      setItem : (key, value) => { return null},
      clear : () => {return null},
      isLocal : () => {return false },
    }
  }

  get(key){
    return this.element.getItem(key)
  }

  set(key, data){
    this.element.setItem(key, data)
    return data
  }

  getObject(key){
    let value = this.element.getItem(key)
    return value ? JSON.parse(value) : null
  }

  setObject(key, data){
    this.element.setItem(key, JSON.stringify(data))
    return data
  }

  clear(){
    this.element.clear()
    if(localStorage){
      localStorage.clear()
    }
    if(sessionStorage){
      sessionStorage.clear()
    }
    if(document && document.cookie){
      let cookies = document.cookie
      
      for (let i = 0; i < cookies.split(';').length; ++i)
      {
        let myCookie = cookies[i]
        let pos = myCookie.indexOf('=')
        let name = pos > -1 ? myCookie.substr(0, pos) : myCookie
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
      }
    }

    return true
  }

  remove(key){
    this.element.removeItem(key)
    return true
  }
}

const Session = new SessionClass()

export default Session
