import {getValues} from './values'
// import jsonpath from 'jsonpath'
// import {getMultilingualString} from './values'

// TODO:
const keysVehicleCharacteristics = [
  {'name' : 'vehicleTypes', 'key': 'vehicleTypes', 'type' : 'array', 'path': ['value']},
  {'name' : 'vehicleUsage', 'key': 'vehicleUsage', 'type' : 'object', 'path': ['value']},
]
const keysVehicles = [
  'vehicleCountryOfOrigin',
  'vehicleIdentifier',
  'vehicleManufacturer',
  'vehicleModel',
  'vehicleRegistrationPlateIdentifier',
  {'name' : 'vehicleStatus', 'key': 'vehicleStatus', 'type' : 'object', 'path': ['value']},
]
const keysTransportUnit = [
  {'name' : '_DgTransportUnitExtension', 'key': '_DgTransportUnitExtension', 'type' : 'type'},
  {'name' : '_BargeExtension', 'key': '_BargeExtension', 'type' : 'type'},
  {'name' : '_ContainerExtension', 'key': '_ContainerExtension', 'type' : 'type'},
  {'name' : '_DgRailWagonInformationExtension', 'key': '_DgRailWagonInformationExtension', 'type' : 'type'},
  {'name' : '_RoadVehicleExtension', 'key': '_RoadVehicleExtension', 'type' : 'type'},
  {'name' : 'connectingCarriers', 'key': 'connectingCarriers', 'type' : 'array', 'path': ['connectingCarrier']},
  'bargeIdent',
  'bicCode',
  'containerIdent',
  {'name' : 'dgtDocumentIds', 'key': 'dgtDocumentIds', 'type' : 'array', 'path': ['id']},
  {'name' : 'bicCode', 'key': 'containers', 'type' : 'array', 'path': ['bicCode']},
  {'name' : 'containerIdent', 'key': 'containers', 'type' : 'array', 'path': ['containerIdent']},
  {'name' : 'dgtDocumentIds', 'key': 'containers', 'type' : 'array', 'path': ['dgtDocumentIds', 'id']},
  'totalQuantityPerCategoryPerTransportUnit',
  'operationalTrainNumber',
  {'name' : 'wagonNumber', 'key': 'wagonNumber', 'type' : 'uic'}, // 'wagonNumber',
  'wagonType',
  'wagonTrainPosition',
  'limitedQuantityDG',
  'commitmentETA',
  'actualETA',
  'containers',
  'dgRailDeliveryPlace',
]

// contains->DGAccompanyingInformation
const keysCoolingSubstances = [
  {'name' : 'unNumber', 'key': 'unNumber', 'type' : 'object', 'path': ['unNumber']},
  {'name' : 'properShippingName', 'key': 'properShippingName', 'type' : 'MultilingualString', 'path': ['properShippingName', 'values', 'values']}, // 1 MultilingualString
]
const keysDGAccompanyingInformation = [
  {'name' : '_DgAccompanyingInformationExtension', 'key': '_DgAccompanyingInformationExtension', 'type' : 'type'},
  'exceptedPackagesClass7S',
  'coolingSubstances',
  {'name' : 'unNumber', 'key': 'coolingSubstances', 'type' : 'array', 'path': ['unNumber', 'unNumber']},
  {'name' : 'properShippingName', 'key': 'coolingSubstances', 'type' : 'array', 'path': ['properShippingName', 'properShippingName', 'values', 'values']}, // 1 MultilingualString
  'meansOfIdentifications',
  'testReportForPackagings',
  'instructionsInWriting',
  'unitFumigations',
  'certificateOfApprovals',
  {'name' : 'totalQuantity1X1X3X6PerTransportUnit', 'key': 'dangerousGoodsQuantities1X1X3X6', 'type' : 'object', 'path': ['totalQuantity1X1X3X6PerTransportUnit']},
  {'name' : 'explosiveContentNetMassTotalTransport', 'key': 'dgtClass1', 'type' : 'object', 'path': ['explosiveContentNetMassTotalTransport']},
  {'name' : 'exceptedQuantities', 'key': 'exceptedQuantities', 'type' : 'object', 'path': ['dgexceptedQuantities']},
  {'name' : 'tankCertificates', 'key': 'tankCertificates', 'type' : 'array', 'path': ['tankCertificate']},
  {'name' : 'expertTrainingCertificates', 'key': 'expertTrainingCertificates', 'type' : 'array', 'path': ['expertTrainingCertificate']},
]

// SIMPLE 
// dgtDocuments
const keysDirectDocument = [
  {'name' : '_DgtDocumentExtension', 'key': '_DgtDocumentExtension', 'type' : 'type'},
  {'name' : '_DgtDocumentClass1Extension', 'key': '_DgtDocumentClass1Extension', 'type' : 'type'},
  {'name' : 'DGTDocumentClass7', 'key': 'DGTDocumentClass7', 'type' : 'type'},
  {'name' : 'carriageIAWs', 'key': 'carriageIAWs', 'type' : 'array', 'path': ['value']},
  {'name' : 'deliveryDate', 'key': 'deliveryDate', 'type' : 'date'}, // 'deliveryDate',
  'id', 
  {'name' : 'pickUpDate', 'key': 'pickUpDate', 'type' : 'date'}, // 'deliveryDate',
  'consignmentTotalActivity',
  {'name' : 'icao', 'key': 'dgConsignmentDescriptionOtherRegulations', 'type' : 'array', 'path': ['icao']},
  {'name' : 'imdgcode', 'key': 'dgConsignmentDescriptionOtherRegulations', 'type' : 'array', 'path': ['imdgcode']},
  {'name' : 'approvalIAW2X2X52X1X8', 'key': 'approvalClasses41And52s', 'type' : 'array', 'path': ['approvalIAW2X2X52X1X8']},
  {'name' : 'dgClassificationFireworks', 'key': 'dgClassificationFireworks', 'type' : 'type'},
]
// dgtDocuments.dgConsignmentDescriptionOtherRegulations
// dgtDocumentEntry
const keysQuantitiesPerShipment = [
  {'name' : '_QuantitiesPerShipmentExtension', 'key': '_QuantitiesPerShipmentExtension', 'type' : 'type'},
  'category1CalculatedValue1X1X3X6PerShipment',
  'category2CalculatedValue1X1X3X6PerShipment',
  'category3CalculatedValue1X1X3X6PerShipment',
  'category4CalculatedValue1X1X3X6PerShipment',
  'totalCalculatedValue1X1X3X6PerShipment',
  'totalQuantityPerCategory0PerShipment',
  'totalQuantityPerCategory1PerShipment',
  'totalQuantityPerCategory2PerShipment',
  'totalQuantityPerCategory3PerShipment',
  'totalQuantityPerCategory4PerShipment',
]
const keysOverpackUseds = [
  {'name' : '_DgOverpackUsedExtension', 'key': '_DgOverpackUsedExtension', 'type' : 'type'},
  'overpackDesignation',
  'overpackTotalQuantity',
]
const keysDirectEntry = [
  {'name' : '_DgtDocumentEntryExtension', 'key': '_DgtDocumentEntryExtension', 'type' : 'type'},
  {'name' : '_DgConsignmentDescriptionStaticClass1Extension', 'key': '_DgConsignmentDescriptionStaticClass1Extension', 'type' : 'type'},
  {'name' : '_DgConsignmentDescriptionStaticClass2Extension', 'key': '_DgConsignmentDescriptionStaticClass2Extension', 'type' : 'type'},
  {'name' : '_DgConsignmentDescriptionStaticClass7Extension', 'key': '_DgConsignmentDescriptionStaticClass7Extension', 'type' : 'type'},
  {'name' : '_DgConsignmentDescriptionStaticClasses41And52Extension', 'key': '_DgConsignmentDescriptionStaticClasses41And52Extension', 'type' : 'type'},
  {'name' : '_EmptyPackagingClass1Extension', 'key': '_EmptyPackagingClass1Extension', 'type' : 'type'},
  {'name' : '_EmptyPackagingClass2Extension', 'key': '_EmptyPackagingClass2Extension', 'type' : 'type'},
  {'name' : '_EmptyPackagingClass3To6And8To9Extension', 'key': '_EmptyPackagingClass3To6And8To9Extension', 'type' : 'type'},
  {'name' : '_EmptyUncleanedLargeExtension', 'key': '_EmptyUncleanedLargeExtension', 'type' : 'type'},
  {'name' : '_NonDGExtension', 'key': '_NonDGExtension', 'type' : 'type'},
  {'name' : '_EmptyUncleanedExtension', 'key': '_EmptyUncleanedExtension', 'type' : 'type'},
  {'name' : '_EmptyPackagingExtension', 'key': '_EmptyPackagingExtension', 'type' : 'type'},
  {'name' : 'approvalCertificateIDMark', 'key': 'approvalCertificateIDMarks', 'type' : 'array', 'path': ['approvalCertificateIDMark', 'value']},
  'emptyUncleanedContainmentLargeType',
  'id',
  'unitTotalQuantityOfDG', // unitquantity ?.value
  'totalQuantityOfDG',
  'waste',
  'environmentallyHazardousSubstance', // environmentally_hazard ?.value
  'numberOfPackages',     // number_of_packages
  {'name' : 'declaration', 'key': 'declaration', 'type' : 'MultilingualString', 'path': ['values', 'values']}, 
  {'name' : 'packageTypeDescription', 'key': 'packageTypeDescription', 'type' : 'MultilingualString', 'path': ['values', 'values']}, 
  {'name' : 'totalQuantityPerTransportUnit', 'key': 'totalQuantityPerTransportUnit', 'type' : 'BigDecimal', 'path': ['dangerousGoodsQuantities', 'totalQuantityPerTransportUnit']}, 
  'controlTempStabilized',
  'emergencyTempStabilized',
  'deliverySale', // deliverysale
  'hot',
  'limitedquantity',
  'packagingDiscardedEmptyUncleaned',
  {'name' : 'salvagePackage', 'key': 'salvagePackage', 'type' : 'object', 'path': ['value']}, 
  'massNewLoad',
  'valvesClosedUntil',
  'exclusiveUseShipment',
  'controlTemp41And52',
  'emergencyTemp41And52',
  'labelConformingModelNo1NotReq',
  'notSRSubstanceClass41',
  'notSubstanceClass52',
  'explosiveContentNetMass',
  'emptyPackagingType', // ?.value emptyPackagingTypes??
  'percentageOfMasses', // ARRAY percentageOfMasses???
  'percentageOfVolumes', // ARRAY percentageOfVolumes???
  // EMPTY
  {'name' : 'dangerLabels', 'key': 'dangerLabels', 'type' : 'array', 'path': ['value']},
  'class1Division', // get by class1Division->value
  'compatibilityClass', // get by compatibilityClass->value
]
// dgtDocuments->dgBulkContainerApproval
const keysBulkContainerApproval = [
  {'name' : '_DgBulkContainerApprovalExtension', 'key': '_DgBulkContainerApprovalExtension', 'type' : 'type'},
  'approvedByCompetentAuthority',
  'bulkContainerType', 
]

// dgtDocuments->dgClassificationFireworks
const keysClassificationFireworks = [
  {'name' : '_DgClassificationFireworksExtension', 'key': '_DgClassificationFireworksExtension', 'type' : 'type'},
  'competentAuthorityIdentification',
  'uniqueSerialReference', 
  'vehicleCountryCode', 
]
// dgtDocumentEntry->dgSpecialProvision
const keysSpecialProvision = [
  {'name' : '_CarriageIAW2X1X2X8Extension', 'key': '_CarriageIAW2X1X2X8Extension', 'type' : 'type'},
  {'name' : '_CarriageIAW2X1X3X5X5Extension', 'key': '_CarriageIAW2X1X3X5X5Extension', 'type' : 'type'},
  {'name' : '_CarriageIAW2X2X7X2X3X5Extension', 'key': '_CarriageIAW2X2X7X2X3X5Extension', 'type' : 'type'},
  {'name' : '_CarriageIAW6X4X11X2Extension', 'key': '_CarriageIAW6X4X11X2Extension', 'type' : 'type'},
  {'name' : '_CarriageIAW6X4X11X3Extension', 'key': '_CarriageIAW6X4X11X3Extension', 'type' : 'type'},
  {'name' : '_NationalSPExtension', 'key': '_NationalSPExtension', 'type' : 'type'},
  {'name' : '_SP290Extension', 'key': '_SP290Extension', 'type' : 'type'},
  {'name' : '_SP310Extension', 'key': '_SP310Extension', 'type' : 'type'},
  {'name' : '_SP318Extension', 'key': '_SP318Extension', 'type' : 'type'},
  {'name' : '_SP356Extension', 'key': '_SP356Extension', 'type' : 'type'},
  {'name' : '_SP363Extension', 'key': '_SP363Extension', 'type' : 'type'},
  {'name' : '_SP367Extension', 'key': '_SP367Extension', 'type' : 'type'},
  {'name' : '_SP373Extension', 'key': '_SP373Extension', 'type' : 'type'},
  {'name' : '_SP376Extension', 'key': '_SP376Extension', 'type' : 'type'},
  {'name' : '_SP378Extension', 'key': '_SP378Extension', 'type' : 'type'},
  {'name' : '_SP390Extension', 'key': '_SP390Extension', 'type' : 'type'},
  {'name' : '_SP392Extension', 'key': '_SP392Extension', 'type' : 'type'},
  {'name' : '_SP581Extension', 'key': '_SP581Extension', 'type' : 'type'},
  {'name' : '_SP582Extension', 'key': '_SP582Extension', 'type' : 'type'},
  {'name' : '_SP583Extension', 'key': '_SP583Extension', 'type' : 'type'},
  {'name' : '_SP623Extension', 'key': '_SP623Extension', 'type' : 'type'},
  {'name' : '_SP640Extension', 'key': '_SP640Extension', 'type' : 'type'},
  {'name' : '_SP642Extension', 'key': '_SP642Extension', 'type' : 'type'},
  {'name' : '_SP650Extension', 'key': '_SP650Extension', 'type' : 'type'},
  {'name' : '_SP662Extension', 'key': '_SP662Extension', 'type' : 'type'},
  {'name' : '_SP664Extension', 'key': '_SP664Extension', 'type' : 'type'},

  {'name' : 'nationalSPs', 'key': 'nationalSPs', 'type' : 'MultilingualString', 'path': ['values', 'values']}, 
  'explosiveContentNetMass',
  'exclusiveUseShipment',
  // dgtDocumentEntry->dgSpecialProvision->SP356
  'metalHydrideStorageSystemApproval', // metalHydrideStorageSystemApproval
  'metalHydrideStorageSystemApprovalDocument', // NEW WHERE?? metalHydrideStorageSystemApprovalDocument

  // dgtDocumentEntry->dgSpecialProvision->SP392
  'containmentSystemsQuantity', 
  'containmentSystemContents', // TODO:

  // dgtDocumentEntry->dgSpecialProvision->SP581
  'sp581Mixture', 
  // dgtDocumentEntry->dgSpecialProvision->SP582
  'sp582Mixture', 
  // dgtDocumentEntry->dgSpecialProvision->SP583
  'sp583Mixture', 

  // dgtDocumentEntry->dgSpecialProvision->SP640
  'substanceClassificationTankCode',

  {'name' : 'gasType', 'key': 'containmentSystemContents', 'type' : 'array', 'path': ['gasType', 'value']},
  {'name' : 'workingPressureUnit', 'key': 'containmentSystemContents', 'type' : 'array', 'path': ['workingPressureUnit', 'value']},
  {'name' : 'quantityNetMass', 'key': 'containmentSystemContents', 'type' : 'array', 'path': ['quantityNetMass']},
  {'name' : 'quantityWorkingPressure', 'key': 'containmentSystemContents', 'type' : 'array', 'path': ['quantityWorkingPressure']},
  {'name' : 'quantityVolumeLiters', 'key': 'containmentSystemContents', 'type' : 'array', 'path': ['quantityVolumeLiters']},
]

// dgtDocumentEntry.dgDescriptionDetails
const keysDirectEntryDescriptionDetails = [
  {'name' : '_DgDescriptionDetailsExtension', 'key': '_DgDescriptionDetailsExtension', 'type' : 'type'}, // 1 TYPE
  {'name' : '_DgtDocumentClass1Extension', 'key': '_DgtDocumentClass1Extension', 'type' : 'type'}, // 1 TYPE
  {'name' : '_DgDescriptionClass7Extension', 'key': '_DgDescriptionClass7Extension', 'type' : 'type'}, // 1 TYPE
  {'name' : '_DgDescriptionClass9Extension', 'key': '_DgDescriptionClass9Extension', 'type' : 'type'}, // 1 TYPE
  // DGDescriptionDetails
  {'name' : 'technicalName', 'key': 'technicalName', 'type' : 'MultilingualString', 'path': ['values', 'values']}, // 1 MultilingualString
  {'name' : 'dangerLabels', 'key': 'dangerLabels', 'type' : 'array', 'path': ['value']},
  'packingGroup', // get by packingGroup->value
  'transportCategory',
  {'name' : 'hazardIdentificationNumber', 'key': 'dgDescriptionRID', 'type' : 'object', 'path': ['hazardIdentificationNumber']}, // 1
  {'name' : 'tunnelRestrictionCode', 'key': 'dgDescriptionADR', 'type' : 'object', 'path': ['tunnelRestrictionCode', 'value']}, // 1
  {'name' : 'unNumber', 'key': 'unNumber', 'type' : 'object', 'path': ['unNumber']},
  {'name' : 'properShippingName', 'key': 'properShippingName', 'type' : 'MultilingualString', 'path': ['properShippingName', 'values', 'values']}, // 1 MultilingualString
  // DGDescriptionClass1
  'class1Division', // get by class1Division->value
  'compatibilityClass', // get by compatibilityClass->value
  // DGDescriptionClass7
  'nameRadionuclide',
  'formDescription',
  'packageCategory',  // get by packageCategory->value
  'transportIndex',
  'criticalitySafetyIndex',
  {'name' : 'carriageIAW2x2x7x2x3x5', 'key': 'carriageIAW2X2X7X2X3X5', 'type' : 'object'},
  {'name' : 'carriageIAW6x4x11x2', 'key': 'carriageIAW6X4X11X2', 'type' : 'object'},
  {'name' : 'carriageIAW6x4x11x3', 'key': 'carriageIAW6X4X11X3', 'type' : 'object'},
  {'name' : 'maxActivity', 'key': 'dgDescriptionRadioactiveContent', 'type' : 'object', 'path': ['maxActivity']}, 
  {'name' : 'massFissileMaterial', 'key': 'dgDescriptionRadioactiveContent', 'type' : 'object', 'path': ['massFissileMaterial']}, 
]

const keysAddress = [
  'name',
  'street',
  'department',
  'zipPostalCode',
  'regionName',
  'cityName',
  'countryCode',
  'countryName',
  'pobox',
  'responsiblePersonName',
  'responsiblePersonPhoneNumber',
]
// Consignee->ConsigneeClass62
const keysResponsible = [
  'responsiblePersonName',
  'responsiblePersonPhoneNumber',
]

/* eslint no-unused-vars: "off" */
// TODO:
const keysContainmentSystemContents = [
  {'name' : '_ContainmentSystemContentExtension', 'key': '_ContainmentSystemContentExtension', 'type' : 'type'},
  'gasType',
  'workingPressureUnit',
  'quantityNetMass',
  'quantityWorkingPressure',
  'quantityVolumeLiters',
]
// contains->ExceptedPackagesClass7
const keysExceptedPackagesClass7 = [
  {'name' : '_ExceptedPackagesClass7Extension', 'key': '_ExceptedPackagesClass7Extension', 'type' : 'type'},
  'containerPackingCertificate',
  'consignee',
  {'name' : 'unNumber', 'key': 'unNumber', 'type' : 'object', 'path': ['unNumber']},
  {'name' : 'properShippingName', 'key': 'properShippingName', 'type' : 'MultilingualString', 'path': ['properShippingName', 'values', 'values']}, // 1 MultilingualString
  'consignor',
  {'name' : 'approvalCertificateIDMark', 'key': 'approvalCertificateIDMarks', 'type' : 'array', 'path': ['approvalCertificateIDMark', 'value']},
  '_ExceptedPackagesClass7Extension',
  {'name' : '_ExceptedPackagesClass7Extension', 'key': '_ExceptedPackagesClass7Extension', 'type' : 'type'},
]

const getLoading = (dgtDocumentEntry) => {
  try{
    let result = { 
      // dgtDocuments_0
      // carriageIAWs:                             getValues(dgtDocuments.carriageIAWs), // dgtDocuments.carriageIAWs?.[0]?.value carriageIAWs??
      // dgtDocumentEntry w_desc: data.lang,
      // packageTypeDescription:                   getValues(dgtDocumentEntry.packageTypeDescription?.values?.values), // dgtDocumentEntry.packageTypeDescription?.values?.values?.[0]?.value packagedescription
      // declaration:                              getValues(dgtDocumentEntry.declaration?.values?.values), // dgtDocumentEntry.declaration?.values?.values[0]?.value
      // overpackDesignation:                      getValues(dgtDocumentEntry.dgOverpackUseds, null, '', 'overpackDesignation'), // dgtDocumentEntry.dgOverpackUseds?.[0]?.overpackDesignation overpackDesignation
      // overpackTotalQuantity:                    getValues(dgtDocumentEntry.dgOverpackUseds, null, '','overpackTotalQuantity'), // dgtDocumentEntry.dgOverpackUseds?.[0]?.overpackTotalQuantity
      // data.dgDescriptionDetails
      ...getValues(dgtDocumentEntry, keysDirectEntry), 
      ...getValues(dgtDocumentEntry.dgSpecialProvisions, keysSpecialProvision),
      ...getValues(dgtDocumentEntry.dgOverpackUseds, keysOverpackUseds), 
      ...getValues(dgtDocumentEntry.dgDescriptionDetails, keysDirectEntryDescriptionDetails )
    }
    return result
  } catch (e) {
    console.error('data:getLoading:', e)
  } 
  return null
}

export const getDocumentEntries = (document) => {
  let entry = null
  let result = null
  let loadings = []
  if(document.dgtDocumentEntries){
    for(let j = 0; j < document.dgtDocumentEntries.length; j++){
      entry = document.dgtDocumentEntries[j]
      // console.log('data:parse', document, entry)
      if(entry){
        result = { 
          // ... document_direct, // TODO
          // ... getValues(document.dgBulkContainerApproval, keysBulkContainerApproval),
          // ... getValues(document.dgClassificationFireworks, keysClassificationFireworks),
          ... getLoading(entry.dgtDocumentEntry ? entry.dgtDocumentEntry : entry),
        }
        loadings.push(result)
      }
    }
  }
  return loadings
}

// ---------------------------------------------------------------------------
export const getDocument = (document) => {
  let entry = null

  let consignees = []
  let consignor = {}
  let loadings = []
  let result = null
  // consignee data
  if(document.consignees){
    for(let j = 0; j < document.consignees.length; j++){
      entry = document.consignees[j]
      if(entry){
        result = { 
          ... getValues(entry.address, keysAddress),
          ... getValues(entry, keysResponsible)
        }
        consignees.push(result)
      }
    }
  }
  // consignor data
  if(document.consignor && document.consignor.address){
    consignor = { 
      ... getValues(document.consignor.address, keysAddress),
      ... getValues(document.consignor, keysResponsible)
    }
  }
    
  let document_direct = getValues(document, keysDirectDocument)
  let bulkContainerApproval = getValues(document.dgBulkContainerApproval, keysBulkContainerApproval)
  let classificationFireworks = getValues(document.dgClassificationFireworks, keysClassificationFireworks)
  let quantitiesPerShipment = getValues(document.quantitiesPerShipment, keysQuantitiesPerShipment)

  loadings = getDocumentEntries(document)

  return {consignor, consignees, loadings, document_direct, bulkContainerApproval, classificationFireworks, quantitiesPerShipment}
}

export const getTransport = (transport) => {
  let results = []
  let result = {}

  let contains = {}
  let meansOfIdentifications = null
  let documents = null
  if(transport.contains){

    contains = {
      ...getValues(transport.contains, keysDGAccompanyingInformation),
      ...getValues(transport.contains.coolingSubstances, keysCoolingSubstances)
    }

    if(transport.contains.meansOfIdentifications && transport.contains.meansOfIdentifications.length > 0){
      meansOfIdentifications = transport.contains.meansOfIdentifications
    }

    if(transport.contains.dgtDocuments){
      documents = transport.contains.dgtDocuments
      for(let i = 0; i < documents.length; i++){
        result = {}
        result.document = getDocument(documents[i])
        // contactPersonName etc
        result.document.meansOfIdentifications = meansOfIdentifications 
        results.push(result)
      }
    }

  }

  return {
    contains,
    documents : results,
  }
}

/*
const test_path = (data, element) => {
  let path = jsonpath.nodes(data, '$..' + element)
  for(let i = 0; i < path.length; i++){
    // console.log(element, path[i].path.join('.'), path[i].value)
  }
}*/

/**
 * 
 * @param {*} data 
 * @returns 
 */
export const parse = (data) => {
  try{
    console.debug('data:parse:', data)
    let result = {}

    // TEST 
    // test_path(data, 'approvalCertificateIDMark')
    // test_path(data, 'deliveryDate')
    // test_path(data, 'pickUpDate')
    // test_path(data, 'consignmentTotalActivity')
    // test_path(data, 'icao')
    // test_path(data, 'imdgcode')
    // test_path(data, 'vehicleRegistrationPlateIdentifier')
    // test_path(data, 'vehicleCountryOfOrigin')
    // test_path(data, 'vehicleTypes')
    // test_path(data, 'packageTypeDescription')
    // est_path(data, 'carriageIAWs')
    // test_path(data, 'totalQuantityPerCategoryPerTransportUnit')
    // test_path(data, 'totalQuantityPerTransportUnit')
    // test_path(data, 'unitTotalQuantityPerTransportUnit')
    // test_path(data, 'unitTotalQuantoverpackDesignationityPerTransportUnit')
    // test_path(data, 'overpackTotalQuantity')

    if(data && data.dgTransport){
      result = getTransport(data.dgTransport)

      if(data.dgTransport.dgTransportUnits){
        result.vehicles = []
        let documents = data.dgTransport.dgTransportUnits
        for(let i = 0; i < documents.length; i++){
          let element = getValues(documents[i], keysTransportUnit)
          if(documents[i].vehicles){
            let vehicles = documents[i].vehicles
            for(let j = 0; j < vehicles.length; j++){
              element = {
                ...element,
                ...getValues(vehicles[j], keysVehicles),
                ...getValues(vehicles[j].vehicleCharacteristics, keysVehicleCharacteristics)
              }
            }
          } 
          result.vehicles.push(element)
        }
      }

      if(result.documents && result.documents.length > 0){
        result.show = true
      }
    }

    // console.log('result1', result) 
    result.data = data
    return result
  } catch (e) {
    console.error('data:parse:', e)
  } 
  return null
}