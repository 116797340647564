/* eslint-disable no-nested-ternary */
import React, {memo, useRef, useCallback, useState, useEffect, lazy } from 'react'
import { Container, Button, Spinner } from 'react-bootstrap'

import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './css/App.css'

// import Content from './page/Content'
import Header from './page/Header.js'
import Footer from './page/Footer.js'
const Empty = lazy(() => import('./page/Empty'))
const Search = lazy(() => import('./page/Search'))
const Register = lazy(() => import('./page/Register'))
const Content = lazy(() => import('./page/Content'))
const ContentAll = lazy(() => import('./page_all/Content'))

import config from './config.json'
import config_data from './config.data.json'
import { getLanguages, getLanguageNext } from './data/values'
import { register }  from './data/service'

import Session from './Session'

const getLabels = (language) => {
  switch(language){
    case 'de' : return require('./i18n_de.json')
    case 'en' : 
    default: 
      return require('./i18n_en.json')
  }
}


const loadUserGet = async() => {
  const response = await register()
  const user = Session.getObject('user')
  console.debug('App:loadUser:', response, user)

  const result = response && response.data && response.data.id ? response.data : null
  let loggedIn = -1
  if(result){
    if(!user){
      // we overwrite always
    } else if(result.id === user.id){
      // we overwrite always
    } else if(result.id !== user.id){ // TODO, necessary?
      Session.clear()
      console.warn('USER:ERROR: Different users, cleared!', result.id, user.id)
    }
    Session.setObject('user', result)
    loggedIn = 1
  } else {
    Session.clear()
  }
  return {user : result, loggedIn :loggedIn, status: response.status}
}

/*
 * TODO: The app is now react 19 ready, but still some things to refactor
   1. Throw out the props drill down
      use createContext, useContext, useReducer with immer and dispatcher!
      Create 3 contexts
      1. user for uer retrival
      2. language for change language
      3. data for search query 
    1-2 days
 */
const App = memo(() => {
  const [data, setData] = useState({
    language : config.language,
    languageNext : null, 
    langaugeNextIndex : -1,
    showDebug : false,
    loggedIn : 0, // init, -1: error, 1: loggedin
    user: null,
    status: 0, // http codes
  })
  const [languages, setLanguages] = useState(null)
  const [dataFields, setDataFields] = useState(null)

  const contentRef = useRef()
  const contentRefAll = useRef()

  const loadUser = useCallback(async() => {
    const user = await loadUserGet()
    setData((prev) => ({
      ...prev,
      ...user,
    }))
  }, [setData])

  const contentRefCall = useCallback(async(props) => {
    const data = await contentRef.current.query({
      ...props,
      showAllContent: contentRefAll.current,
    })
    if(contentRefAll.current){
      contentRefAll.current.setContent(data)
    }
  }, [contentRef, contentRefAll])

  const changeLanguage = useCallback(async() => {
    const nextLanguage = getLanguageNext(languages.keys, data.langaugeNextIndex)

    setData((prev) => ({
      ...prev,
      language : nextLanguage.selected, 
      langaugeNextIndex: nextLanguage.nextIndex, 
      languageNext : nextLanguage.nextLanguage
    }))
  }, [data, setData, languages])

  useEffect(() => {
    const languages = getLanguages(config_data.i18n, config.language) 
    const dataFields = config_data.data
    setLanguages(languages)
    setDataFields(dataFields)
    if(languages && languages.keys && languages.next && languages.next.more){
      setData((prev) => ({
        ...prev,
        languageNext: languages.next.nextLanguage,
        langaugeNextIndex: languages.next.nextIndex,
      }))
    }
    loadUser()
  }, [])

  const { 
    status,
    user,
    loggedIn,
    language,
    languageNext,
    showDebug,
  } = data
  const i18nLabels = getLabels(language)
  const statusError = (status >= 400 && status <= 404) || status > 500

  return (
    <>
      <Header i18nLabels={i18nLabels} changeLanguage={changeLanguage} languageNext={languageNext} user={user} loaduser={loadUser}/>
      {loggedIn === 0 ?
        <Container fluid="md">
          <Spinner animation="border" role="status" variant="primary" className="main-spinner">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Container>
        :
        !user || loggedIn === -1 ?
          <>
            {statusError ? 
              <Empty i18nLabels={status === 404 ? i18nLabels.register.alert.connection : i18nLabels.register.alert['400-403+>500']} />
              :
              <Container fluid="md">
                <Register 
                  i18nLabels={i18nLabels.register} 
                  user={user} 
                  loaduser={loadUser} 
                />
              </Container>
            }
          </>
          :
          !user.contact ?
            <Container fluid="md">
              <Register i18nLabels={i18nLabels.register} user={user} loaduser={loadUser} />
            </Container>
            :
            <Container fluid="md">
              <Search 
                query={contentRefCall}
                i18nLabels={i18nLabels.search}
                language={language}
                refAll={contentRefAll}
              />
              <Content 
                ref={contentRef}
                dataFields={dataFields}
                i18nLabels={i18nLabels.content}
                language={language}
              />
              
              {config.debug && <Button className="noprint" onClick={() => setData((prev) => ({...prev, showDebug: !showDebug}))} variant="secondary" size="sm">ShowAll</Button>}
              {config.debug && <ContentAll ref={contentRefAll} show={showDebug} i18nLabels={i18nLabels.content} language={language} />}
            </Container>
      }
      <Footer i18nLabels={i18nLabels.footer}/>
    </>
  )
})
App.displayName='App'
export default App