import React, {useState, useEffect, memo} from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Button, Form} from 'react-bootstrap'
import { register } from '../data/service'
import Alert from './Alert'

const checkIfAllContactPostalAddressIsSet = (form) => {
  let count = 0
  if((form && form.contact && form.contact.contactAddress !== null) || (form && form.contact && form.contact.contactAddress !== undefined)){
    count += form.contact.contactAddress.cityName !== null && form.contact.contactAddress.cityName.length > 0 ? 1 : 0
    count += form.contact.contactAddress.country !== null && form.contact.contactAddress.country.length > 0 ? 1 : 0
    count += form.contact.contactAddress.postalCode !== null && form.contact.contactAddress.postalCode.length > 0 ? 1 : 0
    count += form.contact.contactAddress.region !== null && form.contact.contactAddress.region.length > 0 ? 1 : 0
    count += form.contact.contactAddress.streetAddresses !== null && form.contact.contactAddress.streetAddresses.length > 0 && form.contact.contactAddress.streetAddresses[0].length > 0 ? 1 : 0
  } else {
    form.actorType = ''
    form.certificate = ''
    form.areaOfCompetence  = ''
    form.contact = {
      additionalContactInformation : '',
      contactAddress : {
        cityName : '',
        country : '',
        postalCode : '',
        region : '',
        streetAddresses : [''],
      },
      contactEmailAddress : '', 
      contactName : '',
      contactResponsibility : '',
      contactTelephoneNumber : '',
    }
    form.postalAddress = {
      cityName : '',
      country : '',
      postalCode : '',
      region : '',
      streetAddresses : [''],
    }
  }
  return count === 0 || count === 5 ? true : false
}

const Register = memo((props) => {
  const { i18nLabels, user, loaduser } = props
  const [message, setMessage] = useState(null)
  const [form, setForm] = useState({
    actorType : '', // minOccurs="1"
    certificate : '', // minOccurs="1"
    areaOfCompetence : '', // minOccurs="1"
    contact : { // minOccurs="1"
      additionalContactInformation : '', // minOccurs="0"
      contactAddress : { // minOccurs="0"
        cityName : '', // minOccurs="0"
        country : '', // minOccurs="0"
        postalCode : '', // minOccurs="0"
        region : '', // minOccurs="0"
        streetAddresses : [''],
      },
      contactEmailAddress : '', 
      contactName : '', // minOccurs="1"
      contactResponsibility : '', // minOccurs="0"
      contactTelephoneNumber : '', // minOccurs="0"
    },
    id : '', // minOccurs="1"
    name : '', // minOccurs="0"
    postalAddress : { // minOccurs="1"
      cityName : '', // minOccurs="1"
      country : '', // minOccurs="1"
      postalCode : '', // minOccurs="1"
      region : '', // minOccurs="1"
      streetAddresses : [''], // minOccurs="1" maxOccurs="2"
    }
  })

  const [submit, setSubmit] = useState(false)
  const updateField = (e, formToChange, index) => {
    // console.log(formToChange, e.target.name, formToChange[e.target.name], index, e.target.value )
    if(typeof index !== 'undefined' && index !== null && index >= 0 && e.target.value !== null && Array.isArray(formToChange[e.target.name])){
      formToChange[e.target.name][index] = e.target.value 
    } else { // else { formToChange[e.target.name] = e.target.value && Array.isArray(formToChange[e.target.name]) ? e.target.value.split(', ') : e.target.value }
      formToChange[e.target.name] = e.target.value 
    }
    setForm({
      ...form,
    })
  }

  const handleSubmit = async(e, form) => {    
    // console.log('handleSubmit', form)
    e.preventDefault()
    setSubmit(true)
    try{
      if(form.actorType.length > 0 && 
        form.areaOfCompetence.length > 0 && 
        form.contact.contactName.length  > 0 &&
        form.postalAddress.cityName.length > 0 &&
        form.postalAddress.country.length > 0 &&
        form.postalAddress.postalCode.length > 0 &&
        form.postalAddress.region.length > 0 &&
        form.postalAddress.streetAddresses.length > 0 &&
        checkIfAllContactPostalAddressIsSet(form)){
        const response = await register(form)
        const result = response.data
        // console.log('handleSubmit', result)
        if(result && result.success){
          setMessage({message : result.success,  type : 'success'})
          await loaduser()
        } else {
          setMessage({message : result.error ? result.error : response.error + ' - Status: ' + response.status, type : 'error'})
        }
      }
    } catch(e){
      setMessage({message : e.message, type : 'error'})
    }
  }

  useEffect(() => {
    if(user && user.id){
      const data = {...user}

      setForm(data)
    }
  }, [user])
  // console.log('search:form:', form, submit)

  const contactAddressSetWrong = !checkIfAllContactPostalAddressIsSet(form)

  const result = []
  if(message){
    const sticky = !(message.type === 'success')
    result.push(<Alert key={0} i18nLabels={i18nLabels} message={message} messageDefault={''} sticky={sticky} />)

    window.scrollTo({
      top: 0,
    })
    // RETURN if success
    if(message.type === 'success'){
      return result
    }
  }

  result.push(
    <Form key={1} className='mt-4 mb-2 noprint' onSubmit={(e) => handleSubmit(e, form)}>
      {form.id && form.id.length > 0 &&
        <Form.Group className="mb-3" controlId="fromId">
          <Row xs={12} md={4} lg={8}>
            <Col>
              <label className="col-form-label required-field">{i18nLabels.id}</label>
            </Col>
            <Col>
              <label className="col-form-label">{form.id}</label>
            </Col>
          </Row>
        </Form.Group>
      }
      <Form.Group className="mb-3" controlId="formname">
        <Row xs={12} md={4} lg={8}>
          <Col>
            <label className="col-form-label">{i18nLabels.name}</label>
          </Col>
          <Col>
            <Form.Control 
              type="text" 
              name='name' 
              value={form.name} 
              onChange={(e) => updateField(e, form)} 
              variant="primary"
              // isInvalid={submit && (!form.name || form.name.length <= 0)}
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formactorType">
        <Row xs={12} md={4} lg={8}>
          <Col>
            <label className="col-form-label required-field">{i18nLabels.actorType}</label>
          </Col>
          <Col>
            <Form.Select aria-label={i18nLabels.label} name='actorType' className="search-select" placeholder={i18nLabels.actorType} value={form.actorType} onChange={(e) => updateField(e, form)}
              isInvalid={submit && (!form.actorType || form.actorType.length <= 0)}
            >
              {Object.keys(i18nLabels.actorTypeSelect).map((key) => {
                return <option key={key} value={key}>{i18nLabels.actorTypeSelect[key]}</option>
              })}
            </Form.Select>
          </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formareaOfCompetence">
        <Row xs={12} md={4} lg={8}>
          <Col>
            <label className="col-form-label required-field">{i18nLabels.areaOfCompetence}</label>
          </Col>
          <Col>
            <Form.Select aria-label={i18nLabels.countrySelect.label} name='areaOfCompetence' className="search-select" placeholder={i18nLabels.countrySelect.label} value={form.areaOfCompetence}
              onChange={(e) => updateField(e, form)}
              isInvalid={submit && (!form.areaOfCompetence || form.areaOfCompetence.length <= 0)}
            >
              {Object.keys(i18nLabels.countrySelect.values).map((key) => {
                return <option key={key} value={key}>{i18nLabels.countrySelect.values[key]}</option>
              })}
            </Form.Select>
          </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3" controlId="fromPost" style={{
        border: '1px',
        borderRadius: '30px',
        borderColor: '#7e7a75',
        borderStyle: 'solid',
        padding: '20px'
      }}>
        <Form.Group className="mb-3" controlId="formpostalAddress1">
          <Row xs={12} md={4} lg={8}>
            <Col>
              <h5 className="col-form-label">{i18nLabels.postalAddress}</h5>
            </Col>
            <Col></Col>
          </Row>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formpostalAddress">
          <Row xs={12} md={4} lg={8}>
            <Col>
              <label className="col-form-label required-field">{i18nLabels.streetAddresses}</label>
            </Col>
            <Col>
              <Form.Control 
                type="text" 
                name='streetAddresses' 
                value={form.postalAddress.streetAddresses && form.postalAddress.streetAddresses.length >= 0 ? form.postalAddress.streetAddresses[0] : ''} 
                onChange={(e) => updateField(e, form.postalAddress, 0)} 
                variant="primary"
                isInvalid={submit && (!form.postalAddress.streetAddresses || form.postalAddress.streetAddresses.length <= 0 || form.postalAddress.streetAddresses[0].length <= 0)}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formpostalAddress1">
          <Row xs={12} md={4} lg={8}>
            <Col></Col>
            <Col>
              <Form.Control 
                type="text" 
                name='streetAddresses' 
                value={form.postalAddress.streetAddresses && form.postalAddress.streetAddresses.length > 1 ? form.postalAddress.streetAddresses[1] : ''} 
                onChange={(e) => updateField(e, form.postalAddress, 1)} 
                variant="primary"
                isInvalid={submit && (!form.postalAddress.streetAddresses || form.postalAddress.streetAddresses.length <= 0)}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formpostalcode">
          <Row xs={12} md={4} lg={8}>
            <Col>
              <label className="col-form-label required-field">{i18nLabels.postalCode}</label>
            </Col>
            <Col>
              <Form.Control 
                type="text" 
                name='postalCode' 
                value={form.postalAddress.postalCode} 
                onChange={(e) => updateField(e, form.postalAddress)} 
                variant="primary"
                isInvalid={submit && (!form.postalAddress.postalCode || form.postalAddress.postalCode.length <= 0)}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formcityName">
          <Row xs={12} md={4} lg={8}>
            <Col>
              <label className="col-form-label required-field">{i18nLabels.cityName}</label>
            </Col>
            <Col>
              <Form.Control 
                type="text" 
                name='cityName' 
                value={form.postalAddress.cityName} 
                onChange={(e) => updateField(e, form.postalAddress)} 
                variant="primary"
                isInvalid={submit && (!form.postalAddress.cityName || form.postalAddress.cityName.length <= 0)}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formregion">
          <Row xs={12} md={4} lg={8}>
            <Col>
              <label className="col-form-label required-field">{i18nLabels.region}</label>
            </Col>
            <Col>
              <Form.Control 
                type="text" 
                name='region' 
                value={form.postalAddress.region} 
                onChange={(e) => updateField(e, form.postalAddress)} 
                variant="primary"
                isInvalid={submit && (!form.postalAddress.region || form.postalAddress.region.length <= 0)}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formcountry">
          <Row xs={12} md={4} lg={8}>
            <Col>
              <label className="col-form-label required-field">{i18nLabels.countrySelect.label}</label>
            </Col>
            <Col>
              <Form.Select aria-label={i18nLabels.countrySelect.label} name='country' className="search-select" placeholder={i18nLabels.countrySelect.label} value={form.postalAddress.country} onChange={(e) => updateField(e, form.postalAddress)}
                isInvalid={submit && (!form.postalAddress.country || form.postalAddress.country.length <= 0)}
              >
                {Object.keys(i18nLabels.countrySelect.values).map((key) => {
                  return <option key={key} value={key}>{i18nLabels.countrySelect.values[key]}</option>
                })}
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>
      </Form.Group>
      <Form.Group className="mb-3" controlId="fromContact" style={{
        border: '1px',
        borderRadius: '30px',
        borderColor: '#7e7a75',
        borderStyle: 'solid',
        padding: '20px'
      }}>
        <Form.Group className="mb-3" controlId="formcontact">
          <Row xs={12} md={4} lg={8}>
            <Col>
              <h5 className="col-form-label">{i18nLabels.contact}</h5>
            </Col>
            <Col></Col>
          </Row>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formcontactname">
          <Row xs={12} md={4} lg={8}>
            <Col>
              <label className="col-form-label">{i18nLabels.contactName}<div style={{display: 'inline', color: '#ff0000'}}> *</div></label>
            </Col>
            <Col>
              <Form.Control 
                type="text" 
                name='contactName' 
                value={form.contact.contactName} 
                onChange={(e) => updateField(e, form.contact)} 
                variant="primary"
                isInvalid={submit && (!form.contact.contactName || form.contact.contactName.length <= 0)}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formcontactphone">
          <Row xs={12} md={4} lg={8}>
            <Col>
              <label className="col-form-label">{i18nLabels.contactTelephoneNumber}</label>
            </Col>
            <Col>
              <Form.Control 
                type="text" 
                name='contactTelephoneNumber' 
                value={form.contact.contactTelephoneNumber} 
                onChange={(e) => updateField(e, form.contact)} 
                variant="primary"
                // isInvalid={submit && (!form.contact.contactTelephoneNumber || form.contact.contactTelephoneNumber.length <= 0)}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="mb-3" controlId="contactEmailAddress">
          <Row xs={12} md={4} lg={8}>
            <Col>
              <label className="col-form-label">{i18nLabels.contactEmailAddress}</label>
            </Col>
            <Col>
              <Form.Control 
                type="text" 
                name='contactEmailAddress' 
                value={form.contact.contactEmailAddress} 
                onChange={(e) => updateField(e, form.contact)} 
                variant="primary"
                // isInvalid={submit && (!form.contact.contactEmailAddress || form.contact.contactEmailAddress.length <= 0)}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="mb-3" controlId="contactResponsibility">
          <Row xs={12} md={4} lg={8}>
            <Col>
              <label className="col-form-label">{i18nLabels.contactResponsibility}</label>
            </Col>
            <Col>
              <Form.Control 
                type="text" 
                name='contactResponsibility' 
                value={form.contact.contactResponsibility} 
                onChange={(e) => updateField(e, form.contact)} 
                variant="primary"
                // isInvalid={submit && (!form.contact.contactResponsibility || form.contact.contactResponsibility.length <= 0)}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="mb-3" controlId="additionalContactInformation">
          <Row xs={12} md={4} lg={8}>
            <Col>
              <label className="col-form-label">{i18nLabels.additionalContactInformation}</label>
            </Col>
            <Col>
              <Form.Control 
                type="text" 
                name='additionalContactInformation' 
                value={form.contact.additionalContactInformation} 
                onChange={(e) => updateField(e, form.contact)} 
                variant="primary"
                // isInvalid={submit && (!form.contact.additionalContactInformation || form.contact.additionalContactInformation.length <= 0)}
              />
            </Col>
          </Row>
        </Form.Group>

        <Row className="justify-content-center"><Col className='mt-1'><hr /></Col></Row>
        <Form.Group className="mb-3" controlId="formcontactAddress">
          <Row xs={12} md={4} lg={8}>
            <Col>
              <label className="col-form-label">{i18nLabels.streetAddresses}</label>
            </Col>
            <Col>
              <Form.Control 
                type="text" 
                name='streetAddresses' 
                value={form.contact.contactAddress.streetAddresses && form.contact.contactAddress.streetAddresses.length > 0 ? form.contact.contactAddress.streetAddresses[0] : ''} 
                onChange={(e) => updateField(e, form.contact.contactAddress, 0)} 
                variant="primary"
                isInvalid={submit && contactAddressSetWrong}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formcontactAddress1">
          <Row xs={12} md={4} lg={8}>
            <Col></Col>
            <Col>
              <Form.Control 
                type="text" 
                name='streetAddresses' 
                value={form.contact.contactAddress.streetAddresses && form.contact.contactAddress.streetAddresses.length > 1 ? form.contact.contactAddress.streetAddresses[1] : ''} 
                onChange={(e) => updateField(e, form.contact.contactAddress, 1)} 
                variant="primary"
                isInvalid={submit && contactAddressSetWrong}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formcontactpostalcode">
          <Row xs={12} md={4} lg={8}>
            <Col>
              <label className="col-form-label">{i18nLabels.postalCode}</label>
            </Col>
            <Col>
              <Form.Control 
                type="text" 
                name='postalCode' 
                value={form.contact.contactAddress.postalCode} 
                onChange={(e) => updateField(e, form.contact.contactAddress)} 
                variant="primary"
                isInvalid={submit && contactAddressSetWrong}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formcontactcityName">
          <Row xs={12} md={4} lg={8}>
            <Col>
              <label className="col-form-label">{i18nLabels.cityName}</label>
            </Col>
            <Col>
              <Form.Control 
                type="text" 
                name='cityName' 
                value={form.contact.contactAddress.cityName} 
                onChange={(e) => updateField(e, form.contact.contactAddress)} 
                variant="primary"
                isInvalid={submit && contactAddressSetWrong}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formcontactregion">
          <Row xs={12} md={4} lg={8}>
            <Col>
              <label className="col-form-label">{i18nLabels.region}</label>
            </Col>
            <Col>
              <Form.Control 
                type="text" 
                name='region' 
                value={form.contact.contactAddress.region} 
                onChange={(e) => updateField(e, form.contact.contactAddress)} 
                variant="primary"
                isInvalid={submit && contactAddressSetWrong}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formcontactcountry">
          <Row xs={12} md={4} lg={8}>
            <Col>
              <label className="col-form-label">{i18nLabels.countrySelect.label}</label>
            </Col>
            <Col>
              <Form.Select aria-label={i18nLabels.countrySelect.label} name='country' className="search-select" placeholder={i18nLabels.countrySelect.label} value={form.contact.contactAddress.country} onChange={(e) => updateField(e, form.contact.contactAddress)}
                isInvalid={submit && contactAddressSetWrong}
              >
                {Object.keys(i18nLabels.countrySelect.values).map((key) => {
                  return <option key={key} value={key}>{i18nLabels.countrySelect.values[key]}</option>
                })}
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>
      </Form.Group>
      <Col className='my-2 align-self-center justify-content-xs-end text-md-center text-sm-end text-end' md={3} lg="2">
        <Button variant="dark" type="submit" name="submit" size="lg">{i18nLabels.button.label}</Button>
      </Col>
    </Form>
  )

  return result
})
Register.displayName = 'Register'

Register.propTypes = {
  user: PropTypes.object,
  loaduser: PropTypes.func.isRequired,
  // search: PropTypes.object.isRequired,
  i18nLabels: PropTypes.object.isRequired,
}

export { Register as default }

/*
      <Row className="justify-content-center">
        <Col className='my-2 text-md-center'><h2>{i18nLabels.label}</h2>
          <hr />
        </Col>
      </Row>
      

      <Form.Group className="mb-3" controlId="formcertificate">
        <Row xs={12} md={4} lg={8}>
          <Col>
            <label className="col-form-label">{i18nLabels.certificate}</label>
          </Col>
          <Col>
            <Form.Control 
              type="text" 
              name='certificate' 
              value={form.certificate} 
              onChange={(e) => updateField(e, form)} 
              variant="primary"
              isInvalid={submit && (!form.certificate || form.certificate.length <= 0)}
              disabled 
              readOnly
            />
          </Col>
        </Row>
      </Form.Group>
*/