/* eslint no-else-return: "off" */
// import { language as browserLanguage, langDecimal } from './common'
import { langDecimal } from './common'

export const getValue = (element, valueDefault = null, keyObject = 'value') => {
  try{
    if(typeof element !== 'undefined' && element !== null){
      if(Array.isArray(element)){
        // console.log('data:getValue:isArray:', element)
        return element.length > 0 ? element : null
      } else if(typeof element === 'object' && typeof element[keyObject] !== 'undefined' && element[keyObject] !== null){
        // console.log('data:getValue:object:', element, valueDefault, keyObject)
        return element[keyObject]
      } else { // string, bool etc
        // console.log('data:getValue:primitive:', element, valueDefault, keyObject)
        return element
      }
    } 
  } catch (e) {
    console.error('data:getValue:', e)
  }
  return valueDefault
}

export const getByPath = (element, path) => {
  let result = null
  if(path){
    result = element
    for(let i = 0; i < path.length; i++){
      if(typeof result !== 'object' || !result || typeof result[path[i]] === 'undefined'){
        break
      }
      // console.log('getSpecial:type:', result, path[i])
      result = result[path[i]]
    }
  }
  return result
}

export const getSpecial = (element, key, valueDefault = null) => {
  let value = null
  switch(key.type){
    case 'uic' : return { type : key.type, value : element }
    case 'date' : return { type : key.type, value : element }
    case 'type' : return key.name // console.log('getSpecial:type:', key.name, element, key)
    case 'MultilingualString' : 
      // console.log('getSpecial:MultilingualString:', getByPath(element, key.path), element, key)
      value = getByPath(element, key.path)
      if(typeof value !== 'undefined' && value !== null) {
        return { type : key.type, value : getByPath(element, key.path) }
      } 
      return value
    case 'array' : {
      if(!element){
        return valueDefault
      }
      let result = []
      for(let i = 0; i < element.length; i++){
        let value = getByPath(element[i], key.path)
        if(value) {
          result.push(value)
        }
      }
      // console.log('getSpecial:array:', result, element, key)
      return result
    }
    case 'object' : 
      // console.log('getSpecial:object:', getByPath(element, key.path), element, key)
      if(!key.path){
        return true
      } else if(!element){
        return valueDefault
      }
      return getByPath(element, key.path)
    default: 
      // console.log('getSpecial:object:', getByPath(element, key.path), element, key)
      if(!key.path){
        return true
      } else if(!element){
        return valueDefault
      }
      return getByPath(element, key.path)
  }
}

export const getValuesKeys = (element, keys, valueDefault = null, keyObject = 'value') => {
  let result = {}
  let i = 0
  let key = null
  for(i = 0; i < keys.length; i++){
    key = keys[i]
    // console.log('data:getValues:object:', key, element[key])
    // NEW
    if(typeof key === 'object' && key.name && key.key && typeof element[key.key] !== 'undefined'){
      result[key.name] = getSpecial(element[key.key], key, valueDefault)
      // console.log('getSpecial:type:', result[key.name], key.name, key.key, key.type, element[key.key], element)
      // LOG
      if(element[key.key] && !result[key.name]){
        if(!key.path || key.path.length > 1 ||
          (key.path.length === 1 && key.path[0] && element[key.key][key.path[0]])){
          // ... console.log('getSpecial:type:', result[key.name], key.name, key.key, key.type, element[key.key], element)
        }
      } // else { console.log('getSpecial:type:', result[key.name], key.name, key.key, key.type, element[key.key], element) }
    } else {
      result[key] = getValue(element[key], valueDefault, keyObject)
      // console.log('data:getValues:object:else:', key, element[key], result[key])
    }
  }
  return result
}
export const getValues = (element, keys, valueDefault = null, keyObject = 'value') => {
  let result = null
  // console.log('data:getValues:', element, keys)
  try{
    if(typeof element !== 'undefined' && element !== null){
      let i = 0
      let key = null
      if(Array.isArray(element)){
        if(keys){
          result = {}
          let elements = []
          for(i = 0; i < element.length; i++){
            // console.log('data:getValues:array:', i, element[i], keys)
            elements.push(getValues(element[i], keys, valueDefault, keyObject))
          }
          for(i = 0; i < elements.length; i++){
            if(elements[i]){
              for (key in elements[i]) {
                if(elements[i][key]){
                  if(!result[key]){
                    result[key] = []
                  }
                  result[key].push(elements[i][key])
                }
              }
            }
          }
          // console.log('data:getValues:array:', element, keys, result, elements, keyObject)
        } else {
          result = []
          for(i = 0; i < element.length; i++){
            result.push(getValue(element[i], valueDefault, keyObject))
          }
          if(result.length <= 0){
            result = null
          }
        }
        // console.log('data:getValues:array:', element, keys, result, keyObject)
      } else {
        result = getValuesKeys(element, keys, valueDefault, keyObject)
        // console.log('data:getValues:array:', element, keys, result, keyObject)
      }
    }
  } catch (e) {
    console.error('data:getValues:', e)
  }
  return result
}
  
export const getValueIfExits = (element, valueDefault = null) => {
  if(element){
    // console.log('data:getValueIfExits', element)
    return element
  } 
  return valueDefault
}

export const getLanguageNext = (keys, index) => {
  let nextLanguage = null
  let nextIndex = null
  let more = false
  let selected = keys[index]
  if(keys.length > 1){
    more = true
    if(keys.length > index + 1){
      nextIndex = index + 1
      nextLanguage = keys[nextIndex]
    } else { // reset
      nextIndex = 0
      nextLanguage = keys[nextIndex]
    }
  }

  return {more, index, selected, nextIndex, nextLanguage}
}

export const getLanguages = (elements, selected = 'en') => {
  let error = null
  let index = -1
  let keys = null
  if(elements){
    keys = Object.keys(elements)
    if(keys && Array.isArray(keys) && keys.length > 0){
      index = keys.indexOf(selected)

      if(index >= 0){
        let next = getLanguageNext(keys, index)
        next.index = index
        next.selected = selected
        return { keys, next}
      }
      error = 'Language not found for' 
    }
    error = 'No other languages for selection'
  }
  error = 'No language object'
  throw Error(error, selected, index, keys.join(', '))
}

/*
  const languages = getLanguages(config.i18n, config.language)
  // console.log(languages.next.selected, 'languages START:', languages)
  if(languages && languages.next.more){
    let nextLanguage = languages.next
    nextLanguage = getLanguageNext(languages.keys, nextLanguage.nextIndex)
    // console.log(nextLanguage.selected, nextLanguage)

    nextLanguage = getLanguageNext(languages.keys, nextLanguage.nextIndex)
    // console.log(nextLanguage.selected, nextLanguage)

    nextLanguage = getLanguageNext(languages.keys, nextLanguage.nextIndex)
    // console.log(nextLanguage.selected, nextLanguage)
    nextLanguage = getLanguageNext(languages.keys, nextLanguage.nextIndex)
    // console.log(nextLanguage.selected, nextLanguage)
  }
*/

export const getMultilingualString = (elements, language = 'en') => {
  if(elements && elements.value && elements.value.length > 0){

    // console.log('getMultilingualString', elements)
    for(let i = 0; i < elements.value.length; i++){
      if(elements.value[i] && elements.value[i].lang && elements.value[i].lang.indexOf(language) >= 0){
        if(typeof elements.value[i].value !== 'undefined' && elements.value[i].value !== null){
          return elements.value[i].value
        }
      }
    }
    if(typeof elements.value[0].value !== 'undefined' && elements.value[0].value !== null){
      return elements.value[0].value
    }
  }
  return null
}

export const removeUnderscore = (element) => {
  if(element){
    return element.replaceAll('_', ' ')
  }
  return null
}

export const isNullish = (obj, labels) => {
  for(const key in obj) {
    // console.log('isNullish', key, labels)
    if (key && typeof labels[key] !== 'undefined' && typeof obj[key] !== 'undefined' && obj[key] !== null) {
      return false
    }
  }
  return true
}

export const formatValue = (i18nLabels, data, language, i18nUnit) => {
  let value = data
  if(Array.isArray(value)){
    if(Array.length > 0){
      if(i18nLabels){
        value = [...data]
        for(let i = 0; i < value.length; i++){
          // console.log('formatValue', value[i], i18nLabels)
          if(typeof value[i] !== 'undefined' && value[i] !== null && i18nLabels[value[i]]){
            // console.log('formatValue', value[i], dataFields)
            value[i] = i18nLabels[value[i]]
          }
          if(typeof i18nUnit !== 'undefined'){ value[i] += ' ' + i18nUnit }
        }
      }
      value = value.join(', ')
    } else {
      value = null
    }
  } else if(typeof data === 'object'){
    if(value.type && value.type === 'MultilingualString'){
      value = getMultilingualString(value, language)
      if(typeof i18nUnit !== 'undefined'){ value += ' ' + i18nUnit }
    } else if(value.type && value.type === 'date'){
      try{
        value = value.value ? (new Date(value.value)).toLocaleString(language) : ''// browserLanguage
      } catch (e){
        console.error('formatValue():date:', value.value, e.message)
        value = value.value
      }
      if(typeof i18nUnit !== 'undefined'){ value += ' ' + i18nUnit }
    } else if(value.type && value.type === 'uic'){
      // 998078098385
      // 99 80 780 9 838-5
      try{
        let temp = value.value
        value = ''
        value += temp.substring(0,2)
        value += ' ' + temp.substring(2,4)
        value += ' ' + temp.substring(4,7)
        value += ' ' + temp.substring(7,8)
        value += ' ' + temp.substring(8,11)
        value += '-' + temp.substring(11)
      } catch (e){
        console.error('formatValue():uic:', value.value, e.message)
        value = value.value
      }
      if(typeof i18nUnit !== 'undefined'){ value += ' ' + i18nUnit }
    } else {
      value = 'ERROR OBJECT'
    }
  } else if(i18nLabels && i18nLabels[value]){
    value = i18nLabels[value]
    if(typeof i18nUnit !== 'undefined'){ value += ' ' + i18nUnit }
  } else if(typeof i18nUnit !== 'undefined'){
    value += ' ' + i18nUnit
  } else if(value && typeof value === 'number'){
    value = langDecimal(value, language)
  }
  return value
}