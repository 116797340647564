import React, { useState, useEffect, memo} from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'react-bootstrap'

let timeout = null
const AlertComponent = memo((props) => {
  const {i18nLabels, message, messageDefault, sticky} = props
  const [show, setShow] = useState(false)

  useEffect(() => {
    // console.log('timeout', timeout)
    if(timeout){ clearTimeout(timeout); timeout = null}
    if(!show && message){ setShow(true) }

    if(!sticky){
      timeout = setTimeout(() => setShow(false) , 5000)
    }
    return () => {
      if(timeout){
        clearTimeout(timeout)
      } 
    }
  }, [message])

  return (
    <>
      {show && message ? 
        <Alert variant={i18nLabels.alert[message.type].color} onClose={() => setShow(false)} dismissible  >
          <h6 className="alert-heading text-center">{ i18nLabels.alert[message.type].heading }</h6>
          {message.message ? 
            (<p className="text-center">
              {message.message} 
            </p>)
            : ''
          }
        </Alert>
        : 
        <h5 className='textcenter2'>{messageDefault}</h5>
      }
    </>
  )
})
AlertComponent.displayName='Alert'

AlertComponent.propTypes = {
  i18nLabels: PropTypes.object.isRequired,
  message: PropTypes.object,
  messageDefault : PropTypes.string,
  sticky: PropTypes.bool,
}
export default AlertComponent